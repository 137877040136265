body {
	font-family: Roboto,verdana,sans-serif;
	font-size: 0.8vw;
}

.app {
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.topbar {
	margin: 2vw;
	text-align: center;
}

.topbar img {
	width: 25vw;
	cursor: pointer;
}

.enter-game, .themes {
	max-width: 40vw;
}

.enter-game input {
    width: 30vw;
	height: 5vh;
	padding: 10px;
    border-radius: 0px;
    box-sizing: border-box;
	margin: 2vh 0;
}

.input-field {
	display: flex;
	align-items: center;
}

.input-title {
	text-align: justify;
}

.enter-game button {
	width: 10vw;
	height: 5vh;
	display: flex;
	align-items: center;
	justify-content: center;
    background-color: #BF665E;
    color: white;
    border: none;
    font-weight: bold;
	cursor: pointer;
	margin-left: 5px;
}

.theme-game .text {
	color: #9e9e9e;
}

.themes {
	display: flex;
	flex-wrap: wrap;
	border-bottom: 1px solid #9e9e9e;
	padding-bottom: 2vh;
	margin-bottom: 2vh;
}

.theme {
	border: 1px solid #253659;
	padding: 2px 4px;
	margin-right: 5px;
	white-space: nowrap;
	margin-top: 10px;
}

.theme:hover {
	cursor: pointer;
	background-color: #04bf9d2e;
}

.custom-theme {
	display: flex;
	flex-direction: column;
}

.custom-theme span {
	width: min-content;
	margin: 0 0 10px;
}

.board {
	width: 50vw;
	min-width: 900px;
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	gap: 10px;
}

.item {
	height: 12vh;
	display: flex;
	padding: 0 10px;
	position: relative;
	align-items: center;
	justify-content: center;
	font-weight: bold;
    background-color: #253659;
	cursor: pointer;
	color: white;
}

.item .material-symbols-outlined {
	position: absolute;
	bottom: 10px;
	right: 10px;
	display: none;
	cursor: pointer;
	font-size: 18px;
	color: black;
}

.item:hover .material-symbols-outlined {
	display: flex;
}

.item span {
	text-align: center;
}

.link {
	color: #9e9e9e;
	margin-bottom: 2vh;
	padding-bottom: 2vh;
	border-bottom: 1px solid #9e9e9e;
}

.info {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 2vh;
}

.info > div {
	width: 20%;
}

.players span {
	font-weight: bold;
}

.info .text {
	text-align: center;
}

.info .action {
	text-align: end;
}

.questions {
	display: flex;
	align-items: center;
}

.questions input {
    width: 90%;
	height: 5vh;
	padding: 10px;
    border-radius: 0px;
    box-sizing: border-box;
	margin: 2vh 0;
}

.questions button {
	width: 10%;
	height: 5vh;
	display: flex;
	align-items: center;
	justify-content: center;
    background-color: #BF665E;
    color: white;
    border: none;
    font-weight: bold;
	cursor: pointer;
	margin-left: 5px;
}